<template>
  <Page
    class="
      bg-[#01082A]
      bg-microscopio
      bg-microscopio-posicao-2
      bg-microscopio-tamanho-2
      before:hidden
      before:h-screen
      before:w-screen
      before:fixed
      before:pointer-events-none
      before:top-0
      before:left-0
      md:before:block
      md:before:bg-blueg-md
      md:bg-microscopio-posicao-2-md
      md:bg-microscopio-tamanho-2-md
    "
  >
    <slot />
  </Page>
</template>

<script>
import Page from "@/components/atoms/Page.vue";

export default {
  components: {
    Page,
  },
};
</script>
