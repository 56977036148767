<template>
  <div class="p-o-programa">
    <TOPrograma>
      <div class="flex flex-col items-center md:text-lg">
        <div class="text-center md:-m-3 md:mb-14">
          <router-link to="/">
            <img
              class="w-56 inline-block align-top md:w-72 xl:w-96"
              src="@/assets/novo/logo-rastrear-home.svg"
              alt="Programa RAStrear Logo"
            />
          </router-link>
        </div>
        <section
          class="text-white text-center pb-12 px-6 max-w-[1200px] md:rounded-lg md:bg-[#033665] md:px-14 md:py-14 md:mx-6 md:mb-10"
        >
          <p class="font-bold mb-5 md:text-xl mt-8">
            SOBRE O PROGRAMA RASTREAR
          </p>
          <p class="font-light mb-4">
            O Programa RASTREAR é mais uma iniciativa AMGEN® visando
            proporcionar mais conforto e facilidades a médicos e pacientes
            envolvidos em tratamento ultilizando seus medicamentos.
          </p>
          <p class="font-light">
            Atualmente o programa conta com exames diagnósticos RAS/BRAF,
            incluindo mutação KRAS G12C, voltado a pacientes com câncer
            colorretal metastático, estadio IIIb e IV.
          </p>
        </section>
        <section
          class="bg-[#F2F2F2] pt-8 pb-16 flex flex-col items-center justify-center max-w-[1200px] md:rounded-lg md:px-14 md:pt-14 md:pb-0 md:mx-6 md:mb-14 md:shadow-xl"
        >
          <inline-svg
            aria-label="Amgen"
            class="align-middle inline-block w-36 md:w-40"
            :src="require('@/assets/amgen.svg')"
            title="Amgen"
          />
          <div class="text-[#C5C5C5] text-center px-6 pt-3">
            <p class="text-[#4C97C6] font-bold pb-5 md:text-xl">
              SOBRE A AMGEN®
            </p>
            <p class="pb-4">
              A Amgen® é uma das líderes mundiais em biotecnologia e baseia sua
              atuação em valores profundamente enraizados na ciência e na
              inovação.
            </p>
            <p class="pb-5">
              Nossos medicamentos geralmente tratam de doenças para as quais há
              opções de tratamento limitadas, ou são medicamentos que fornecem
              uma opção viável de tratamento, que de outra forma não estaria
              disponível. O tratamento de milhões de pacientes com doenças
              complexas em todo o mundo depende da produção segura e confiável
              de medicamentos biológicos que sejam administrados por injeção ou
              por via intravenosa. Líder mundial em fabricação de produtos
              biológicos, a Amgen possui um histórico notável na entrega
              confiável de medicamentos de alta qualidade para os pacientes em
              todo mundo
            </p>
            <a
              class="rounded-[40px] inline-block text-sm w-[200px] py-3 bg-white shadow-xl md:-mb-56 md:mt-6 md:relative md:-bottom-2 md:text-base"
              href="https://www.amgen.com.br/"
              target="_blank"
            >
              <span class="text-[#4C97C6] font-bold">Conheça mais</span>
            </a>
          </div>
        </section>
      </div>
    </TOPrograma>
  </div>
</template>

<script>
import TOPrograma from "@/components/templates/OPrograma.vue";

export default {
  name: "OPrograma",
  components: {
    TOPrograma,
  },
};
</script>
